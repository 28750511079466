import m from 'mithril';

import { marked } from 'marked';
import DOMPurify from 'dompurify';

import '../css/pages/policy_detail.scss';
import '../css/pages/policy_detail_print.scss';

export class PolicyDetailPage {
    view(vnode) {
        return m('main', [
            m('article#policy-detail', [
                m('div.head.flex.one.three-fifth-600', [
                    m('h1', vnode.attrs.policyObj.title),
                    m('div.policy-detail-serial', [
                        m('span.serial', {title: 'Copy permalink to clipboard', onclick: this.onRequestCopyPermalink(vnode.attrs.policyObj.id)}, vnode.attrs.policyObj.id),
                        m('i.permalink-icon.material-icons', 'link'),
                    ]),
                ]),
                m('div.content.flex', [
                    m('aside.meta.one.third-600', [
                        m('p.item', [
                            m('span', 'Policy Type'),
                            m('span', vnode.attrs.policyObj.doctype)
                        ]),
                        m('p.item', [
                            m('span', 'Published On'),
                            m('span', vnode.attrs.policyObj.datePublished),
                        ]),
                        m('p.item', [
                            m('span', 'Sectors'),
                            m('div.policy-category-tags', [
                                ...vnode.attrs.policyObj.sectors.map((v) => {
                                    return m('span.label.sector', v);
                                }),
                            ]), 
                        ]),
                        m('p.item', [
                            m('span', 'Themes'),
                            m('div.policy-category-tags', [
                                ...vnode.attrs.policyObj.themes.map((v) => {
                                    return m('span.label.theme', v);
                                }),
                            ]), 
                        ]),
                        !(vnode.attrs.policyObj.link.url) // If URL does not exist...
                        ? m('[')
                        : m('p.item.none.full-text', [
                            m('span', 'Full Text Link'),
                            m('span', vnode.attrs.policyObj.link.url), 
                        ]),
                        m('div.links', [
                            !(vnode.attrs.policyObj.link.url) // If URL does not exist...
                            ? m('[')
                            : m('button.full-text', {onclick: this.goToLocation(vnode.attrs.policyObj.link.url)},
                                [
                                    m('i.material-icons', 'visibility'),
                                    'View Full Text',
                                ],
                            ),
                            m('button', {onclick: () => window.print()},
                                [
                                    m('i.material-icons', 'print'),
                                    'Print',
                                ],
                            )
                            //m('a.button', 'Edit Annotation'),
                        ]),
                    ]),
                    m('div.text.one.two-third-600', [
                        m.trust(DOMPurify.sanitize(marked.parse(vnode.attrs.policyObj.summary))),
                    ]),
                ]),
            ]),
            /*
            m('aside#policy-detail-meta-aside.none.fifth-600', [
                m('div.links', [
                    m('button', {
                        disabled: !(vnode.attrs.policyObj.link.url),
                        // Disable button if no link is available
                        onclick: this.goToLocation(vnode.attrs.policyObj.link.url)},
                        'View Full Text'
                    ),
                    //m('a.button', 'Edit Annotation'),
                ]),
                m('div.item', [
                    m('span', 'Year Published'),
                    m('span', vnode.attrs.policyObj.yearPublished),
                ]),
                m('div.item', [
                    m('span', 'Sector'),
                    m('span', vnode.attrs.policyObj.sectors.join(', ')),
                ]),
                m('div.item', [
                    m('span', 'Thematic Area'),
                    m('span', vnode.attrs.policyObj.themes.join(', ')),
                ]),
                m('div.item', [
                    m('span', 'Type'),
                    m('span', vnode.attrs.policyObj.type),
                ]),
            ]),
            */
        ]);
    }

    goToLocation(href) {
        return () => {
            window.open(href, '_blank');
        };
    }

    onRequestCopyPermalink(policyId) {
        return async () => {
            if(!navigator.clipboard) {
                // Fallback
                return;
            }

            try {
                const copyPermObj = await navigator.permissions.query({ name: 'clipboard-write' });

                if(copyPermObj.state != "granted" && copyPermObj.state != "prompt") {
                    alert("Write access denied!");
                    return;
                }
                
                await navigator.clipboard.writeText();
            }
            catch(err) {
                console.error(err);
            }
        };
    }
}