import m from 'mithril';

import { FooterView } from '../components/FooterView';
import { NavbarView } from '../components/NavbarView';

export class PolicyThemePage {
    constructor() {
        this.policySectors = [
            {
                id: 'institutional',
                name: 'Institutional',
                colorClass: 'bg-color-teal',
                showThemesClass: 'none',
                showIcon: 'expand_more',
                iconSrc: 'static/icons/sector_inst.svg',
                count: 180,
                themes: [
                    {id: 'lgu', name: 'Local Government', count: 100},
                    {id: 'eo', name: 'Executive Office', count: 20},
                    {id: 'leg', name: 'Legislative', count: 10},
                    {id: 'jud', name: 'Judiciary', count: 50},
                ],
            },
            {
                id: 'physical',
                name: 'Physical',
                colorClass: 'bg-color-yellow',
                showThemesClass: 'none',
                showIcon: 'expand_more',
                iconSrc: 'static/icons/sector_phys.svg',
                count: 260,
                themes: [
                    {id:'transpo', name: 'Transportation/Mobility', count: 100},
                    {id: 'infra', name: 'Infrastructure', count: 50},
                    {id: 'evac', name: 'Evacuation', count: 10},
                    {id: 'util', name: 'Utilities', count: 10},
                    {id: 'ict', name: 'ICT', count: 20},
                    {id: 'border', name: 'Border and Jurisdiction Control', count: 70},
                ],
            },
            {
                id: 'social',
                name: 'Social',
                colorClass: 'bg-color-maroon',
                showThemesClass: 'none',
                showIcon: 'expand_more',
                iconSrc: 'static/icons/sector_soc.svg',
                count: 160,
                themes: [
                    {id: 'educ', name: 'Education', count: 10},
                    {id: 'social', name: 'Social Services', count: 20},
                    {id: 'ethnic', name: 'Ethnic and Minority Affairs', count: 10},
                    {id: 'labor', name: 'Labor', count: 30},
                    {id: 'curfew', name: 'Curfew', count: 50},
                    {id: 'food', name: 'Food Security', count: 20},
                    {id: 'prohibit', name: 'Prohibition', count: 20},
                ],
            },
        ];
    }

    oncreate(vnode) {
        this.expandSectorDivs(vnode.attrs.policySectorId);
    }

    onupdate(vnode) {

    }

    view(vnode) {
        return [
            m(NavbarView, {activeIdx: 1}),
            m('main', [
                m('section#policy-sector-accordion', this.policySectors.map((v) => {
                    return m('div.item', {'data-sector-id': v.id}, [
                        m(m.route.Link, {selector: 'a.header.flex.two', href: `/sector/${v.id}`, onclick: (e) => (this.onClickExpandBtn(e))}, [
                            m('h3', v.name),
                            m('span.btn-show-detail.material-icons', v.showIcon),
                        ]),
                        m('div.themes.flex.one.two-600', {class: v.showThemesClass}, [
                            m('div.card.sector-marker', {class: v.colorClass}, ''),
                            m('div.list', v.themes.map((eachTheme) => {
                                return m(m.route.Link, {selector: 'a.card.flex.one.two-600', href: `/search?s=${v.id}&t=${eachTheme.id}`, style: `background: linear-gradient(to right, #00ca51 ${50 + 50 * (eachTheme.count / v.count)}%, #fefefe ${50 - 50 * (eachTheme.count / v.count)}%);`}, [
                                    m('span', eachTheme.name),
                                    m('span', `${eachTheme.count} policies`),
                                ]);
                            })),
                        ])
                    ]);
                })),
            ]),
            m(FooterView),
        ];
    }

    onClickExpandBtn(e) {
        const sectorId = e.currentTarget.parentElement.dataset.sectorId;
        const idxInThemesList = this.policySectors.findIndex((v2) => { return v2.id == sectorId });

        if(this.policySectors[idxInThemesList].showThemesClass != 'none') {
            this.expandSectorDivs(-1);
        }
        else {
            this.expandSectorDivs(idxInThemesList);
        }
    }
    
    expandSectorDivs(expandedSectorId) {
        // Unexpand other sectors
        for(let i = 0; i < this.policySectors.length; ++i) {
            if(i == expandedSectorId) {
                this.policySectors[i].showThemesClass = '';
                this.policySectors[i].showIcon = 'expand_less';
            }
            else {
                this.policySectors[i].showThemesClass = 'none';
                this.policySectors[i].showIcon = 'expand_more';
            }
        }
    }
}