import m from 'mithril';

import '../css/pages/add_policy.scss';

export class AddPolicyPage {
    view(vnode) {
        return m('main', [
            m('h3', 'Add Policy'),
            m('div.flex.three-600', [
                m('form#add-policy.full.third-600', {method: 'post'}, [
                    m('div', [
                        m('h4.stack', 'Basic'),
                        m('input.stack', {type: 'text', name: 'policy-short-name', placeholder: 'Short Name'}),
                        m('input.stack', {type: 'text', name: 'policy-long-name', placeholder: 'Long Name', required: true}),
                        m('input.stack', {type: 'text', name: 'policy-pub-type', placeholder: 'Policy Type', required: true}),
                        m('input.stack', {type: 'date', name: 'policy-pub-date', placeholder: 'Publication Date', required: true}),
                    ]),
                    m('div', [
                        m('h4.stack', 'Scope'),
                        m('select.stack', {name: 'policy-scope', placeholder: 'Scope', required: true}, [
                            m('option', {disabled: true}, 'Scope'),
                            m('option', {value: 1}, 'National'),
                            m('option', {value: 2}, 'Provincial'),
                            m('option', {value: 3}, 'City/Municipial'),
                            m('option', {value: 4}, 'Barangay'),
                        ]),
                        m('select.stack', {name: 'policy-scope-natl'}, [
                            m('option', {disabled: true}, 'National Agency'),
                        ]),
                        m('select.stack', {name: 'policy-scope-prov'}, [
                            m('option', {disabled: true}, 'Province'),
                        ]),
                        m('select.stack', {name: 'policy-scope-lwgn'}, [
                            m('option', {disabled: true}, 'City/Municipality'),
                        ]),
                        m('select.stack', {name: 'policy-scope-brgy'}, [
                            m('option', {disabled: true}, 'Barangay'),
                        ]),
                    ]),
                    m('div', [
                        m('h4.stack', 'Tags'),
                        m('input.stack', {name: 'policy-sectors', placeholder: 'Sectors', required: true}),
                        m('input.stack', {name: 'policy-themes', placeholder: 'Thematic Areas', required: true}),
                    ]),
                    m('div', [
                        m('h4.stack', 'Policy Annotation'),
                        m('textarea.stack', {name: 'policy-summary', placeholder: 'Summary', required: true}),
                        m('textarea.stack', {name: 'policy-justification', placeholder: 'Justification'}),
                        m('button.stack.icon-paper-plane', {type: 'submit', onclick: this.onAddPolicy(vnode)}, [
                            'Submit',
                            m('i.material-icons', 'send'),
                        ]),
                    ]),
                ]),
                m('div#add-policy-preview.full.two-third-600', [
                    m('h3', 'Preview'),
                ]),
            ]),
            m('div.modal', [
                m('input#cbx-confirm-add', {type: 'checkbox'}),
                m('label.overlay', {for: 'cbx-confirm-add'}),
                m('article', [
                    m('header', [
                        m('h3', 'Confirm Add Policy'),
                        m('label.close', {for: 'cbx-confirm-add'}, [
                            m('i.material-icons', 'close'),
                        ]),
                    ]),
                    m('section.content', [
                        'Are you sure you want to add this policy to the Sourcebook?',
                    ]),
                    m('footer', [
                        m('button', 'Yes'),
                        m('label.button.dangerous', {for: 'cbx-confirm-add'}, 'No'),
                    ])
                ]),
            ]),
        ]);
    }

    onAddPolicy(vnode) {
        return (evt) => {
            evt.preventDefault();

            vnode.dom.querySelector('#cbx-confirm-add').checked = true;
        };
    }
}