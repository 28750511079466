import m from 'mithril';
import { saveAs } from 'file-saver';

import { SearchBarView } from '../components/SearchBarView';
import { UPRILoader } from "../components/UPRILoader";

import 'js-snackbar/dist/js-snackbar.css';
import Snackbar from 'js-snackbar/dist/js-snackbar';

import '../css/pages/search_result.scss';

export class SearchResultPage {
    oninit(vnode) {
        this.resultsArr = [vnode.attrs.results];
    }

    view(vnode) {
        return m('main', [
            m('div.flex.one.five-600', [
                m('div.main.three-fifth-600', [
                    m(SearchBarView, {inputValue: vnode.attrs.kwords, presenter: vnode.attrs.searchBarPresenter}),
                    m('div#search-results', [
                        this.resultsArr[0].length > 0
                        ? m('[')
                        : m('div.card.error', [
                            m('h3', 'No search results!'),
                            m('p', [
                                'Seems like ',
                                m('b', vnode.attrs.kwords),
                                ' did not match any policies.',
                            ]),
                            m('p', [
                                'Try the following:',
                                m('ul', [
                                    m('li', 'Make sure all words are spelled correctly'),
                                    m('li', 'Try different keywords'),
                                    m('li', 'Try more general keywords'),
                                    m('li', 'Try fewer keywords'),
                                ]),
                            ]),
                        ]),
                        ...this.resultsArr.map((eachResultBlock, eachResultBlockIdx) => {
                            return m('[', [
                                eachResultBlockIdx <= 0
                                ? m('[')
                                : m('div.page-sep', [
                                    m('span.index', eachResultBlockIdx + 1),
                                ]),
                                ...eachResultBlock.map((v) => {
                                    return m('article.card', {'data-policy-id': v.id}, [
                                        m('h3', [
                                            m(m.route.Link, {href: v.link}, v.title),
                                        ]),
                                        m('div.policy-category-tags', [
                                            ...v.sectors.map((v2) => {
                                                return m('span.label.sector', v2);
                                            }),
                                            ...v.themes.map((v2) => {
                                                return m('span.label.theme', v2); 
                                            }),
                                        ]),
                                        m('p', v.summary),
                                    ]);
                                }),
                            ]);
                        }),
                    ]),
                    m('div.search-page-more', [
                        this.resultsArr[0].length <= 0
                            ? m('[')
                            : (
                                this.resultsArr[this.resultsArr.length - 1].length < vnode.attrs.limit
                                ? m('button.view-more', {disabled: true}, 'No More Results')
                                : m('button.view-more', {onclick: (evt) => this.loadMoreResults(evt, vnode.state, vnode.attrs.presenter)}, 'More Results...')
                            ),
                    ]),
                ]),
            ],
            [
                m('div#search-results-margin.one.two-fifth-600', [
                    this.resultsArr[0].length > 0
                        ? m('div.card#sbook-dl', [
                            m('h3', 'Download Sourcebook'),
                            m('p', 'You can download a PDF copy of the policy contents of these search results by pressing on the "Download" button below.'),
                            m('div.download-btn', [
                                m(UPRILoader, {id: 'loader-generate-sbook', className: 'hide'}),
                                m('button#download-sbook-btn.view-more', {onclick: (evt) => this.startSbookGen(evt, vnode.dom, vnode.attrs.presenter)}, [
                                    'Download',
                                    m('i.material-icons', 'download'),
                                ]),
                            ])
                        ])
                        : m('['),
                ]),
            ])
        ]);
    }

    async startSbookGen(evt, vnodeDom, presenter) {
        evt.target.disabled = true;

        let sbookId = null;
        const sbookIdInButton = 'sbookId' in vnodeDom.querySelector('#download-sbook-btn').dataset;

        if(!sbookIdInButton) {
            vnodeDom.querySelector('#loader-generate-sbook').classList.remove('hide');

            Snackbar({
                message: 'Generating sourcebook...',
                timeout: 3000,
                status: 'success',
                icon: 'warn',
                dismissible: true,
                fixed: true,
            });

            sbookId = await presenter.generateSbookFromPolicies();
            vnodeDom.querySelector('#download-sbook-btn').dataset.sbookId = sbookId;
        }
        else {
            sbookId = vnodeDom.querySelector('#download-sbook-btn').dataset.sbookId;
        }

        if(sbookId) {
            Snackbar({
                message: 'Downloading sourcebook...',
                timeout: 3000,
                status: 'success',
                icon: 'warn',
                dismissible: true,
                fixed: true,
            });

            const fileBlob = await presenter.fetchSbookFile(sbookId);

            saveAs(fileBlob, `${sbookId}.pdf`);
        }

        evt.target.disabled = false;
        vnodeDom.querySelector('#loader-generate-sbook').classList.add('hide');
    }

    async loadMoreResults(evt, state, presenter) {
        evt.target.disabled = true;

        const searchResults = await presenter.loadNext();

        state.resultsArr.push(searchResults);

        evt.target.disabled = false
        m.redraw();
    }
}