import m from "mithril";

import { Chart, ArcElement, DoughnutController, Tooltip } from "chart.js";
import { DoughnutCenterTextPlugin } from '../components/DoughnutCenterTextPlugin';
import { UPRILoader } from "../components/UPRILoader";

export class PolicyStatsSection {
    constructor() {
        this.pieColors = [
            '#218380',
            '#ffbc42',
            '#831b3d',
            '#73d2de',
            '#00ca51',
            '#d81159'
        ];

        this.currentSectorIndex = 0;
        this.previewPolicies = [];
    }

    oncreate(vnode) {
        Chart.register(ArcElement, DoughnutController, Tooltip);
        Chart.register(DoughnutCenterTextPlugin);

        const sumPolicies = vnode.attrs.sectors.reduce((acc, val) => acc + val.count, 0);
        const mainPieCanvas = vnode.dom.querySelector('#cht-policy-pie canvas');
        // const iconImg = vnode.dom.querySelector('#cht-policy-detail .detail .icon img.top');

        // iconImg.src = firstPolicy.iconSrc;

        this.mainChtObj = new Chart(mainPieCanvas.getContext('2d'), {
            type: 'doughnut',
            data: {
                labels: vnode.attrs.sectors.map((v) => v.name),
                datasets: [{
                    label: 'Main',
                    data: vnode.attrs.sectors.map((v) => v.count),
                    backgroundColor: this.pieColors,
                    hoverBorderWidth: 2,
                    hoverBorderColor: 'black',
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                borderWidth: 0,
                plugins: {
                    doughnutCenterText: {
                        text: `${sumPolicies} Policies`,
                        color: 'black',
                        fontStyle: 'Barlow',
                        sidePadding: 20,
                        minFontSize: 16,
                        maxFontSize: 24,
                        lineHeight: 15,
                    },
                },
            },
        });
    }
    
    view(vnode) {
        return m('section#index-policy-stats', [
            m('h2', 'Policy Statistics'),
            m('div.stats-container', [
                m('div#cht-policy-pie.card-float', [
                    m('div', [
                        m('h3', 'Total Policies'),
                        //m('select', []),
                    ]),
                    m('div.cht-container', [
                        m('canvas', {onclick: this.onClickCanvas(vnode)}),
                    ]),
                ]),
                m('div#cht-policy-detail.card-float.hide', [
                    m('div.detail', [
                        // m('div.icon', [
                        //     m('img.top', {width: '64px'}),
                        //     m('img.fade-out', {width: '64px'}),
                        // ]),
                        m('div.cht-container', [
                            m('canvas'),
                        ]),
                    ]),
                    m(UPRILoader, {id: 'loader-policy-stats', className: 'hide'}),
                    m('div.preview-policies', [
                        m('h4', `${vnode.attrs.sectors[vnode.state.currentSectorIndex].name}-related Policies`),
                        ...vnode.state.previewPolicies[vnode.state.currentSectorIndex]?.map((eachPolicy) => {
                            return m('article.card', [
                                m(m.route.Link, {href: `/policy/${eachPolicy.id}`}, [
                                    m('h5', eachPolicy.title),
                                ]),
                                m('div.policy-category-tags', [
                                    ...eachPolicy.themes?.map((v2) => {
                                        return m('span.label.theme', v2); 
                                    }) ?? [],
                                ]),
                            ]);
                        }) ?? [],
                        m('button.view-more', {onclick: (evt) => {m.route.set('/search', {q: `sec:"${vnode.attrs.sectors[vnode.state.currentSectorIndex].name}"`})}}, 'View All'),
                    ]),
                ]),
            ]),
        ]);
    }

    onClickCanvas(vnode) {
        const thisObj = vnode.state;

        return (evt) => {
            const activePoints = thisObj.mainChtObj.getElementsAtEventForMode(evt, 'nearest', {intersect: true}, true);
            
            if(activePoints.length > 0) {
                this.showDetailChart(vnode);
                
                const firstPoint = activePoints[0];
                const thisPolicyLabel = thisObj.mainChtObj.data.labels[firstPoint.index];
                const thisPolicyNum = thisObj.mainChtObj.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
                const sumPolicies = thisObj.mainChtObj.data.datasets[firstPoint.datasetIndex].data.reduce((acc, val) => acc + val, 0);

                // Update icon
                // const iconTopImg = vnode.dom.querySelector('#cht-policy-detail .icon img.top');
                // const iconFadeImg = vnode.dom.querySelector('#cht-policy-detail .icon img.fade-out');
                // iconFadeImg.src = iconTopImg.src;
                // iconTopImg.src = vnode.attrs.sectors[firstPoint.index].iconSrc;

                // Update detail chart
                const newBgColors = thisObj.mainChtObj.data.datasets[firstPoint.datasetIndex].data.map((_, i) => {
                    if(firstPoint.index == i) {
                        return thisObj.mainChtObj.data.datasets[firstPoint.datasetIndex].backgroundColor[i];
                    }
                    else {
                        return 'transparent';
                    }
                });

                thisObj.chtDetailObj.data.datasets[firstPoint.datasetIndex].backgroundColor = newBgColors;
                thisObj.chtDetailObj.options.plugins.doughnutCenterText.text = `${Math.round(thisPolicyNum / sumPolicies * 100)}% ${thisPolicyLabel}`;
                thisObj.chtDetailObj.update();

                thisObj.currentSectorIndex = firstPoint.index;

                this.updatePreviewPolicies(vnode, thisObj.currentSectorIndex);
            }
        };
    }

    showDetailChart(vnode) {
        const firstPolicy = vnode.attrs.sectors[0];
        const sumPolicies = vnode.attrs.sectors.reduce((acc, val) => acc + val.count, 0);
        const detailPieCanvas = vnode.dom.querySelector('#cht-policy-detail .detail .cht-container canvas');
        const chtPolicyDetail = vnode.dom.querySelector('#cht-policy-detail');

        if(!chtPolicyDetail.classList.contains('hide')) {
            return
        }

        chtPolicyDetail.classList.remove('hide');
        chtPolicyDetail.classList.add('animate-show');

        vnode.state.chtDetailObj = new Chart(detailPieCanvas.getContext('2d'), {
            type: 'doughnut',
            data: {
                labels: vnode.attrs.sectors.map((v, i) => i == 0 ? v.name : 'None'),
                datasets: [{
                    label: 'Detail',
                    data: vnode.attrs.sectors.map((v) => v.count),
                    backgroundColor: vnode.attrs.sectors.map((_, i) => i == 0 ? '#218380' : 'transparent'),
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                borderWidth: 0,
                cutout: '65%',
                plugins: {
                    tooltip: {
                        enabled: false,
                    },
                    doughnutCenterText: {
                        text: `${Math.round(firstPolicy.count / sumPolicies * 100)}% ${firstPolicy.name}`,
                        color: 'black',
                        fontStyle: 'Barlow',
                        sidePadding: 15,
                        minFontSize: 16,
                        maxFontSize: 24,
                        lineHeight: 15,
                    },
                },
            },
        });
    }

    async updatePreviewPolicies(vnode, currentSectorIndex) {
        // Update preview policies
        const loaderView = vnode.dom.querySelector('#loader-policy-stats');
        const previewPoliciesView = vnode.dom.querySelector('#cht-policy-detail.card-float .preview-policies');
        loaderView.classList.remove('hide');
        previewPoliciesView.classList.add('hide');

        const previewPolicies = await vnode.attrs.presenter.fetchPoliciesBySector(vnode.attrs.sectors[currentSectorIndex].name, {limit: 3});
        vnode.state.previewPolicies[currentSectorIndex] = previewPolicies;

        setTimeout(() => {
            loaderView.classList.add('hide');
            previewPoliciesView.classList.remove('hide');
            m.redraw();
        }, 500);
    }
}