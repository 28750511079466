import m from 'mithril';

import { FooterView } from '../components/FooterView';
import { NavbarView } from '../components/NavbarView';

export class AboutPage {
    view(vnode) {
        return m('main', [
            m('h3', 'About the Sourcebook'),
            m('p', [
                'This policy sourcebook is a part of the contribution of the University of the Philippines Resilience Institute (UP RI) to the efforts of the UP COVID-19 Pandemic Response Team in pursuit of the University of the Philippines\' mandate as public service university. It is a collaborative product of the development team from Research and Creative Work Division which aims to help the public, students, scholars, researchers, policymakers, and practitioners in the intersecting fields of public health, disaster risk management, and crisis governance, by providing a comprehensive list of policies adopted by the government to address the COVID-19 pandemic. It has two volumes: Volume I covers national issuances, while Volume II focuses on local government policies.'
            ]),
            m('h3', 'Contributors'),
            m('h5', 'Policy Collation and Annotation'),
            m('ul', [
                m('li', ''),
            ]),
            m('h5', 'Website and ICT'),
            m('ul', [
                m('li', ''),
            ]),
        ]);
    }
}