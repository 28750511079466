// https://www.figma.com/proto/EQpcdDrbUsDxttQbqlzlMr/UPRI-Project?node-id=998%3A6845&scaling=contain&page-id=993%3A1465&starting-point-node-id=1028%3A2643

import m from 'mithril';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

import { GlideCarousel } from '../components/GlideCarousel';
import { SearchBarView } from '../components/SearchBarView';
import { PolicyStatsSection } from './PolicyStatsSection';

import pandemicScrim from '../../static/covid19_scrim.svg';
import '../css/pages/index.scss';

export class IndexPage {
    constructor() {
        this.choiceBtns = [
            {
                id: 'institutional',
                name: 'Institutional',
                cardClasses: 'bg-color-teal',
                iconSrc: 'static/icons/sector_inst.svg',
                numPolicies: 40,
            },
            {
                id: 'physical',
                name: 'Physical',
                cardClasses: 'bg-color-yellow',
                iconSrc: 'static/icons/sector_phys.svg',
                numPolicies: 20,
            },
            {
                id: 'social',
                name: 'Social',
                cardClasses: 'bg-color-maroon',
                iconSrc: 'static/icons/sector_soc.svg',
                numPolicies: 15,
            },
            {
                id: 'security',
                name: 'Security',
                cardClasses: 'bg-color-sky-blue',
                iconSrc: 'static/icons/sector_sec.svg',
                numPolicies: 10,
            },
            {
                id: 'environment',
                name: 'Environment',
                cardClasses: 'bg-color-light-green',
                iconSrc: 'static/icons/sector_env.svg',
                numPolicies: 5,
            },
            {
                id: 'health',
                name: 'Health',
                cardClasses: 'bg-color-light-red',
                iconSrc: 'static/icons/sector_heal.svg',
                numPolicies: 5,
            },
        ];

        this.heroImages = [
            {
                name: 'COVID-19',
                src: 'static/covid19_scrim.svg',
            }
        ];
    }

    oncreate(vnode) {
        
    }

    view(vnode) {
        return m('main#main-index', [
            m(SearchBarView, {presenter: vnode.attrs.searchBarPresenter}),
            m('hr'),
            m('h1', 'Welcome to the Policy Sourcebook!'),
            m('section#index-hero', [
                m(GlideCarousel, {
                    options: {  
                        keyboard: 'false',
                        perView: 1,
                    },
                    slides: [
                        ...this.heroImages.map((v) => {
                            return m('img', {src: pandemicScrim});
                        }),
                    ]
                }),
            ]),
            m(PolicyStatsSection, {presenter: vnode.attrs.presenter, sectors: vnode.attrs.sectors.map((sectorCountObj) => {
                return {
                    id: sectorCountObj.id,
                    name: sectorCountObj.name,
                    cardClasses: 'bg-color-yellow',
                    iconSrc: 'static/icons/sector_phys.svg',
                    count: sectorCountObj.count,
                }
            })}),
            m('section#index-policy-feature', [
                m('h2', 'Featured Policies'),
                m(GlideCarousel, {
                    options: {  
                        keyboard: 'false',
                        perView: 3,
                        breakpoints: {
                            600: {
                                perView: 1,
                            }
                        },
                    },
                    slides: vnode.attrs.randomPolicies.map((v) => {
                        return m('li.glide__slide', [
                            m('article.card', [
                                m(m.route.Link, {href: `/policy/${v.id}`}, [
                                    m('h3', v.title),
                                ]),
                                m('div.policy-category-tags', [
                                    ...v.sectors.map((v2) => {
                                        return m('span.label.sector', v2);
                                    }),
                                    ...v.themes.map((v2) => {
                                        return m('span.label.theme', v2); 
                                    }),
                                ]),
                                m('div.content', [
                                    m.trust(DOMPurify.sanitize(marked.parse(v.summary))),
                                ]),
                                m(m.route.Link, {selector: 'a.footer-link', href: `/policy/${v.id}`}, [
                                    m('span.text', [
                                        'Read More'
                                    ]),
                                ]),
                            ]),
                        ]);
                    }),
                }),
            ]),
        ]);
    }
}