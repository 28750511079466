import m from 'mithril';
import { UPRILoader } from '../components/UPRILoader';

import 'js-snackbar/dist/js-snackbar.css';
import Snackbar from 'js-snackbar/dist/js-snackbar';

import 'animate.css';
import '../css/pages/feedback.scss';

export class FeedbackPage {
    view(vnode) {
        return m('main', [
            m('h1', 'Feedback'),
            m('div.flex.one.five-600', [
                m('div.main.three-fifth-600', [
                    m('p', 'Please write any feedback regarding the Sourcebook Portal here. Note that your feedback will be sent to the UP Resilience Institute. If you provide a valid email address, we may be able to reply to your feedback. Thank you!'),
                    m('form#form-feedback', {method: 'post'}, [
                        m('div', [
                            m('input.stack', {id:'feedback-name', name: 'feedback-name', type: 'text', placeholder: 'Name'}),
                            m('input.stack', {id:'feedback-email', name: 'feedback-email', type: 'email', placeholder: 'Email Address'}),
                            m('input.stack', {id:'feedback-phone', name: 'feedback-phone', type: 'phone', placeholder: 'Phone Number', autocomplete: false, tabIndex: -1, style: 'display: none !important;'}),
                            m('select.stack', {id:'feedback-type', name: 'feedback-type', placeholder: 'Feedback Type', required: true}, [
                                m('option', {value: '', selected: true, disabled: true}, 'Select feedback type...'),
                                m('option', {value: 'bug'}, 'Bug'),
                                m('option', {value: 'uiux'}, 'UI/UX'),
                                m('option', {value: 'feature'}, 'Feature Request'),
                                m('option', {value: 'inquiry'}, 'Inquiry'),
                                m('option', {value: 'others'}, 'Others'),
                            ]),
                            m('textarea.stack', {id:'feedback-comments', name: 'feedback-comments', rows: 5, placeholder: 'Comments', required: true}),
                            m('textarea.stack', {id:'feedback-suggestions', name: 'feedback-suggestions', placeholder: 'Suggestions', autocomplete: false, tabIndex: -1, style: 'display: none !important;'}),
                            m('button#btn-feedback-submit.stack.icon-paper-plane', {type: 'submit', onclick: this.onSubmitFeedback(vnode)}, [
                                'Submit',
                                m('i.right-icon.material-icons', 'send'),
                            ]),
                        ]),
                    ]),
                ]),
            ]),
        ]);
    }

    onSubmitFeedback(vnode) {
        return async (evt) => {
            evt.preventDefault();
            
            vnode.dom.querySelector('#btn-feedback-submit').disabled = true;

            // Validate form
            const validatedFields = this.validateForm(vnode);

            if(Object.keys(validatedFields).length <= 0) {
                vnode.dom.querySelector('#btn-feedback-submit').disabled = false;
                return;
            }
            
            // Send form
            try {
                await vnode.attrs.presenter.sendFeedback(validatedFields);

                setTimeout(() => {
                    Snackbar({
                        message: 'Feedback submitted!',
                        timeout: 3000,
                        status: 'success',
                        icon: 'warn',
                        dismissible: true,
                        fixed: true,
                    });
        
                    vnode.dom.querySelector('#btn-feedback-submit').disabled = false;
        
                    // Clear forms
                    vnode.dom.querySelector('#form-feedback').reset();
                }, 800);
            }
            catch(e) {
                Snackbar({
                    message: 'There was a problem submitting your feedback.',
                    timeout: 3000,
                    status: 'warn',
                    icon: 'warn',
                    dismissible: true,
                    fixed: true,
                });

                vnode.dom.querySelector('#btn-feedback-submit').disabled = false;
            }
        };
    }

    validateForm(vnode) {
        const nameField = vnode.dom.querySelector('#feedback-name');
        const emailField = vnode.dom.querySelector('#feedback-email');
        const phoneField = vnode.dom.querySelector('#feedback-phone');
        const typeField = vnode.dom.querySelector('#feedback-type');
        const commentField = vnode.dom.querySelector('#feedback-comments');
        const suggestionField = vnode.dom.querySelector('#feedback-suggestions');
        const setInvalidField = (elm, message) => {
            Snackbar({
                message: message,
                timeout: 3000,
                status: 'error',
                icon: 'warn',
                dismissible: true,
                fixed: true,
            });

            elm.classList.add('invalid', 'animate__animated', 'animate__headShake');
            elm.focus();
            
            elm.addEventListener('animationend', () => {
                elm.classList.remove('animate__headShake');
            }, {once: true});

            return {};
        };

        if(emailField.value.trim().length > 0) {
            // Check valid email
            if(!/^\S+@\S+$/.test(emailField.value.trim())) {
                return setInvalidField(emailField, 'Please enter a valid email address.');
            }
        }

        // Check feedback type
        if(!['bug', 'uiux', 'feature', 'inquiry', 'others'].includes(typeField.value)) {
            return setInvalidField(typeField, 'Please choose a comment/feedback type.');
        }

        // Check if there is something in the comment
        if(commentField.value.trim().length <= 0) {
            return setInvalidField(commentField, 'Please enter something in the comment box.');
        }

        emailField.classList.remove('invalid');
        typeField.classList.remove('invalid');
        commentField.classList.remove('invalid');

        return {
            name: nameField.value.trim(),
            phone: phoneField.value.trim(),
            email: emailField.value.trim().length > 0 ? emailField.value.trim() : undefined,
            type: typeField.value,
            comment: commentField.value.trim(),
            suggestion: suggestionField.value.trim(),
        };
    }
}